<template>
  <div class="main-container-lr">
    <div class="container-l" style="overflow-y: scroll; display: block">
      <el-tree
        ref="tree"
        :highlight-current="true"
        default-expand-all
        :data="treeLeftData"
        :current-node-key="leftChoose.id"
        node-key="id"
        :props="defaultProps"
        @node-click="handleNodeClick"
      ></el-tree>
    </div>
    <div class="container-r">
      <div class="pt-wrapper">
        <!-- 搜索 -->
        <div class="hd-search">
          <table-search
            :model="searchParams"
            label-width="80px"
            @search="searchFn('search')"
            :isResetByPublic="false"
            @clearSearch="clearSearchFn"
            :flex="'4'"
          >
            <search-item default label="">
              <el-input
                size="small"
                v-model="searchParams.dicName"
                placeholder="字典标签"
                clearable
              />
            </search-item>
          </table-search>
        </div>
        <div class="bd-content">
          <!-- 操作按钮 -->
          <div class="table-opt">
            <el-button
              type="primary"
              class="el-icon-circle-plus-outline"
              @click="addDict('3')"
            >
              新增
            </el-button>
          </div>
          <!-- 表格 -->
          <pageTable
            ref="table"
            :columns="columnList"
            :params="searchParams"
            :api-function="this.$api.sysManage.queryCategoryList"
            :initFalse="true"
          >
            <template slot="options" slot-scope="scope">
              <div class="data-operate">
                <span class="op-blue" @click="addDict('4', scope.row)">
                  编辑
                </span>
                <span class="op-red" @click="del(scope.row.id)"> 删除 </span>
              </div>
            </template>
            <template slot="dicName" slot-scope="scope">
              <div class="data-operate">{{ scope.row }}</div>
            </template>
          </pageTable>
        </div>
      </div>
    </div>

    <add-alert
      :dialogVisible.sync="dialogVisible"
      :dialog-type="dialogType"
      @update="searchFn"
      @updatetree="updateTree"
      :formData="editData"
      v-if="dialogVisible"
    />
  </div>
</template>

<script>
import addAlert from './alertAction.vue';
export default {
  name: 'dictNextManage',
  components: {
    addAlert,
  },
  data() {
    return {
      columnList: [
        {
          label: '序号',
          type: 'index',
          width: window.innerWidth >= 1920 ? 80 : 60,
        },

        {
          label: '字典标签',
          prop: 'dicName',
        },
        {
          label: '字典值',
          prop: 'dicCode',
        },
        {
          label: '所属字典',
          prop: 'dicCategory',
        },
        // {
        //   label: '排序',
        //   prop: 'dictValue1',
        // },

        {
          label: '操作',
          prop: 'options',
          show: 'template',
          width: window.innerWidth >= 1920 ? 300 : 200,
        },
      ],
      searchParams: {
        dicPcode: this.$route.query.id,
        dicName: '',
      },
      defaultProps: {
        children: 'lowerLevel',
        label: 'dicName',
      },
      treeLeftData: [],
      dialogVisible: false,
      dialogType: '',
      editData: {},
      leftChoose: {},
    };
  },

  activated() {
    this.loadData();
  },
  mounted() {
    this.loadData();
  },
  methods: {
    clearSearchFn() {
      this.searchParams = {
        dicPcode: this.$route.query.id,
        dicName: '',
      };
      this.$nextTick(() => {
        this.searchFn('search');
      });
    },
    async loadData() {
      await this.loadLeftTreeData();
      this.leftChoose = this.treeLeftData[0];
      this.setTreeChoose();
      this.searchFn('search');
    },
    //设置树左侧选中的高亮
    setTreeChoose() {
      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(this.leftChoose.id);
      });
    },
    del(id) {
      this.$msg.confirm('确认删除字典？').then(() => {
        this.$api.sysManage
          .removeDict({
            id: id,
          })
          .then(() => {
            this.searchFn('del');
            this.updateTree();
          });
      });
    },
    addDict(type, data) {
      // this.form = {
      //   dictParentValue: this.parentCode,
      // };
      // this.$refs.form && this.$refs.form.resetFields();
      //新增和编辑把父级的这几个字段传过去
      this.editData.dicCategory = this.leftChoose.dicName; //父级
      this.editData.dicPcode = this.leftChoose.id; //父级id
      this.editData.jb = parseInt(this.leftChoose.jb) + 1; //父级+1 是子需要的等级
      // 编辑把自己的信息传过去
      if (type == '4') {
        this.editData.dicCode = data.dicCode;
        this.editData.dicName = data.dicName;
        this.editData.id = data.id;
      } else {
        this.editData.dicCode = '';
        this.editData.dicName = '';
        this.editData.id = '';
      }
      this.dialogType = type;
      this.dialogVisible = true;
    },
    // 搜索
    searchFn(status) {
      this.$refs.table.getPageDataOfAction(status);
    },
    //左侧树结构的data
    async loadLeftTreeData() {
      let res = await this.$api.sysManage.queryNextategoryList(
        this.$route.query.id.toString()
      );
      this.treeLeftData = [res.data];
    },
    //点击左侧 请求右侧的表格数据
    handleNodeClick(data) {
      this.leftChoose = data;
      this.searchParams.dicPcode = data.id;
      this.searchFn('search');
    },

    /**
     * 更新左侧树
     */
    async updateTree() {
      await this.loadLeftTreeData();
      this.setTreeChoose();
    },
  },
};
</script>
<style lang="scss" scoped>
.container-r {
  background: #f0f2f7;
}
.el-tabs {
  width: 100%;
  height: 100%;
  background: #fff;
}
::v-deep .el-tabs__content {
  height: calc(100% - 6%);
}
::v-deep .el-tab-pane {
  width: 100%;
  height: 100%;
}
.tree-wrapper {
  .tree-serach {
    border-bottom: 1px solid #cfd9e6;
  }
}
.pt-wrapper {
  padding: 15px;
}
::v-deep .search-wrap {
  margin-bottom: 10px;
}
</style>
